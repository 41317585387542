<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span> {{ item }} </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="branch">Branch</vs-th>
        <vs-th sort-key="material_code">Material Code</vs-th>
        <vs-th sort-key="material_desc">Material Desc</vs-th>
        <vs-th sort-key="principal">Principal</vs-th>
        <vs-th sort-key="storage">Storage</vs-th>
        <vs-th sort-key="location">Location</vs-th>
        <vs-th sort-key="uom">UOM</vs-th>
        <vs-th sort-key="stock_on_hand">Stock On Hand</vs-th>
        <vs-th sort-key="reserved_qty">Reserved Qty</vs-th>
        <vs-th sort-key="allocated_qty">Allocated Qty</vs-th>
        <vs-th sort-key="available_qty">Available Qty</vs-th>
        <vs-th sort-key="inventory_total">Inventory Total</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].branch">
            {{ data[indextr].branch }}
          </vs-td>
          <vs-td :data="data[indextr].material_code">
            {{ data[indextr].material_code }}
          </vs-td>
          <vs-td :data="data[indextr].material_desc">
            {{ data[indextr].material_desc }}
          </vs-td>
          <vs-td :data="data[indextr].principal">
            {{ data[indextr].principal }}
          </vs-td>
          <vs-td :data="data[indextr].storage">
            {{ data[indextr].storage }}
          </vs-td>
          <vs-td :data="data[indextr].location">
            {{ data[indextr].location }}
          </vs-td>
          <vs-td :data="data[indextr].uom"> {{ data[indextr].uom }} </vs-td>
          <vs-td :data="data[indextr].stock_on_hand">
            {{ formatPrice(data[indextr].stock_on_hand) }}
          </vs-td>
          <vs-td :data="data[indextr].reserved_qty">
            {{ formatPrice(data[indextr].reserved_qty) }}
          </vs-td>
          <vs-td :data="data[indextr].allocated_qty">
            {{ formatPrice(data[indextr].allocated_qty) }}
          </vs-td>
          <vs-td :data="data[indextr].available_qty">
            {{ formatPrice(data[indextr].available_qty) }}
          </vs-td>
          <vs-td :data="data[indextr].inventory_total">
            {{ formatPrice(data[indextr].inventory_total) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    principalIDs: {
      type: Array,
    },
    principalNames: {
      type: Array,
    },
    skuCodes: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportSTLevel.tableData,
      total: (state) => state.reportSTLevel.total,
      totalPerPage: (state) => state.reportSTLevel.totalPerPage,
      totalPage: (state) => state.reportSTLevel.totalPage,
      totalSearch: (state) => state.reportSTLevel.totalSearch,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({ getTableData: 'reportSTLevel/getSTLevelReport' }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: '',
        order: 'branch',
        sort: 'desc',
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, 'All'],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == 'All' ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.getTableData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        territory:
          this.territoryIDs.length == 0
            ? undefined
            : this.territoryIDs.join(','),
        sku_code:
          this.skuCodes.length == 0 ? undefined : this.skuCodes.join(','),
        principal:
          this.principalIDs.length == 0
            ? undefined
            : this.principalIDs.join(','),
      }).then((resp) => {
        this.$vs.loading.close();
        this.table.data = resp.records;
      });
    },
    setStartEnd() {
      let valStart =
        this.tableData.length * this.table.page - this.tableData.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.tableData.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (this.totalSearch < this.table.total && this.table.search != '') {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY');
    },
    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        'ARTRANS_REPORT_' +
        (this.territoryNames.length > 0
          ? this.territoryNames.join('-')
          : 'all') +
        '_';

      if (this.startDocDate || this.endDocDate) {
        fileTitle +=
          'DOC_DATE_' +
          moment(this.startDocDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endDocDate).format('YYYY-MM-DD') +
          '_';
      }

      if (this.startPostingDate || this.endPostingDate) {
        'POSTING_DATE_' +
          moment(this.startPostingDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endPostingDate).format('YYYY-MM-DD') +
          '_';
      }

      if (this.startDueDate || this.endDueDate) {
        'DUE_DATE_' +
          moment(this.startDueDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endDueDate).format('YYYY-MM-DD') +
          '_';
      }

      fileTitle +=
        'OPEN_KEY_DATE_' + moment(this.openKeyDate).format('YYYY-MM-DD');

      this.$http
        .get(this.baseUrl + '/export', {
          params: {
            territory_ids: this.territoryIDs,
            start_posting_date: this.startPostingDate
              ? moment(this.startPostingDate).format('YYYY-MM-DD')
              : null,
            end_posting_date: this.endPostingDate
              ? moment(this.endPostingDate).format('YYYY-MM-DD')
              : null,
            start_doc_date: this.startDocDate
              ? moment(this.startDocDate).format('YYYY-MM-DD')
              : null,
            end_doc_date: this.endDocDate
              ? moment(this.endDocDate).format('YYYY-MM-DD')
              : null,
            start_due_date: this.startDueDate
              ? moment(this.startDueDate).format('YYYY-MM-DD')
              : null,
            end_due_date: this.endDueDate
              ? moment(this.endDueDate).format('YYYY-MM-DD')
              : null,
            open_key_date: this.openKeyDate
              ? moment(this.openKeyDate).format('YYYY-MM-DD')
              : null,
            file: file,
            title: fileTitle,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == 'error') {
            this.$vs.notify({
              color: 'danger',
              title: 'Error',
              text: resp.message,
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-x-circle',
            });
          } else {
            this.$vs.notify({
              color: 'success',
              title: 'Processing',
              text: resp.message,
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-x-circle',
            });
          }
        });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    format(head, value) {
      if (typeof head['format'] === 'function') {
        var f = head['format'];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startPostingDate() {
    //   this.getData();
    // },
    // endPostingDate() {
    //   this.getData();
    // },
    // startDocDate() {
    //   this.getData();
    // },
    // endDocDate() {
    //   this.getData();
    // },
    // startDueDate() {
    //   this.getData();
    // },
    // endDueDate() {
    //   this.getData();
    // },
    // openKeyDate() {
    //   this.getData();
    // },
    // territoryID() {
    //   this.getData();
    // },
    draw() {
      this.getData();
    },
    tableData(val) {
      this.table.data = val;
    },
    total(val) {
      this.table.total = val;
    },
    totalPerPage(val) {
      this.table.totalPerPage = val;
    },
    totalPage(val) {
      this.table.totalPage = val;
    },
    totalSearch(val) {
      this.table.totalSearch = val;
    },
    'table.data'() {
      this.setStartEnd();
    },
  },
  // mounted() {
  //   this.getData();
  // },
};
</script>
